@import url(https://fonts.bunny.net/css?family=montserrat:500|source-sans-pro:400);

.navbar {
    height: 60px; /* Adjust this value as per your requirement */
    font-family: 'Montserrat', sans-serif;
    color: #000;    
    backdrop-filter: blur(10px) brightness(75%) saturate(80%);
    box-shadow: 10px 5px 10px 5px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
    border-radius: 30px;
    width:90%;
    position: fixed;
    left: 5%;
}


.nav-link:hover{

  color: #D8DEE9; 

}


.nav-link{
    color: #4C566A;
    
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show{
  color: #fff;
}

.nav-link:focus {
  color: #fff;
}

.navigation-buttons span.prev {
  background: #222 url(/public/images/nav-prev.9b7656600ce9922302fa591f0be905b7.png) no-repeat 5px 5px;
}

.navigation-buttons span.next {
  background: #222 url(/public/images/nav-next.dacef369fbedad44046aa690538793ff.png) no-repeat 5px 5px;
}

.navigation-buttons span {
  width: 30px;
  height: 30px;
  margin-top: 6px;
  border-radius: 3px;
  display: block;
  cursor: pointer;
}

body {
  font-family: Arial, sans-serif;
  color: #D8DEE9;
}

  .introduction {
    box-shadow: 0 13px 61px rgba(0, 0, 0, 0.1);
    background-color: #000;
    background-image: url("https://www.transparenttextures.com/patterns/xv.png");
    padding:150px 10px 20px 10px;
  }
  
  .profile-picture img {
    width: 350px;
    height: 350px;
    border-radius: 50%;
    object-fit:cover;
    object-position:-110px;
  }

  @media (max-width: 855px) {
    .profile-picture img {
      width: 300px;
      height: 300px;
    }
  }

  .introduction h1 {
    font-size: 3.2rem;
    margin-bottom: 20px;
    font-family: 'Montserrat', sans-serif;
  }
  
  .introduction p {
    font-size: 2.2rem;
    line-height: 1.3;
    font-family: 'Source Sans Pro', sans-serif;
  }

  @media (max-width: 855px) {
    .currplaying{
      display: none;
    }
  }
  
  div.profile-picture{
    padding:10px 0 10px 0;
  }

  div.content{
    padding: 55px 40px 55px 40px;
  }

  /* Responsive adjustments */
  @media (max-width: 1000px) {
    .introduction .row {
      align-items: center;
      flex-direction: column;
      text-align: center;
    }

    .introduction h1 {
      font-size: 2.2rem;
    }
    
    .introduction p {
      font-size: 0.6 rem;

    }

  }

  .about-me {
    position: relative;
    padding: 40px 60px 40px 60px;
    background-color: #000000;
    background-image: url("https://www.transparenttextures.com/patterns/stardust.png");

  }

  .about-me h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    font-family: 'Montserrat', sans-serif;
    padding: 50px 0 20px 0;
  }
  
  .about-me p {
    font-size: 1.2rem;
    line-height: 1.6;
    font-family: 'Source Sans Pro', sans-serif;
  }

   #social-media-footer ul li {
    font-size: 48px;
    padding: 16px;
    transition: 0.4s;
  }

   #social-media-footer ul {
    display: flex;
    list-style: none;
    justify-content: Left;
    padding: 0;
  }

 #social-media-footer a .fa-instagram, 
 #social-media-footer a .fa-linkedin,
 #social-media-footer a .fa-twitter,
 #social-media-footer a .fa-github {
  color: #4C566A;
  transition: 0.4s;
  font-size: 64px;
}

@media (max-width: 900px) {
 #social-media-footer a .fa-instagram, 
 #social-media-footer a .fa-linkedin,
 #social-media-footer a .fa-twitter,
 #social-media-footer a .fa-github {
  font-size: 48px;
  }
}

@media (max-width: 400px) {
  #social-media-footer a .fa-instagram, 
  #social-media-footer a .fa-linkedin,
  #social-media-footer a .fa-twitter,
  #social-media-footer a .fa-github {
   font-size: 32px;
   }
 }

 @media (max-width: 350px) {
  #social-media-footer a .fa-instagram, 
  #social-media-footer a .fa-linkedin,
  #social-media-footer a .fa-twitter,
  #social-media-footer a .fa-github {
   font-size: 24px;
   }
 }
  
   #social-media-footer ul li:hover a .fa-instagram {
    color: #f71c63;
  }
  
   #social-media-footer ul li:hover a .fa-linkedin {
    color: #0072b1;
  }
  
   #social-media-footer ul li:hover a .fa-github {
    color: #bbb;
  }
   #social-media-footer ul li:hover a .fa-twitter {
    color: #1a8cd8;
  }

  .resume {
    padding: 40px 0;
    background-color: #000000;
    background-image: url("https://www.transparenttextures.com/patterns/shattered-dark.png");

  }

  #resume h5 {
    padding:20px 0;
    font-weight: bold;
  }
  
  #resume h3{
    padding:20px 0;
    font-weight: bold;
  }
  .container {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .profile {
    text-align: center;
  }
  
  .profile img {
    width: 200px;
    border-radius: 50%;
    margin-bottom: 15px;
  }
  
  .profile h2 {
    font-size: 48px;
    margin-bottom: 5px;
  }
  
  .profile p {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .content h3 {
    font-size: 32px;
    margin-bottom: 15px;
  }

  
  .content p {
    font-size: 16px;
    line-height: 1.6;
  }
  
  .content ul {
    list-style: none;
    padding-left: 0;
  }
  
  .content li {
    margin-bottom: 8px;
  }

  .services {
    padding: 40px 30px;
    text-align: left;
    background-color: #000000;
    background-image: url("https://www.transparenttextures.com/patterns/white-diamond.png");
  }
  
  .services h2 {
    padding-top: 30px;
    font-size: 3.2em;
  }
  .container {
    max-width: 1200px;
    margin: 20px auto;
  }
  
  
  .service-item {
    margin: 40px;
  }
  
  i {
    font-size: 64px;
    color: #4C566A;
    margin-bottom: 20px;
    transition: 2s;
  }

  i:hover {
    color: #D8DEE9;
    
  }


  .projects {
    padding: 60px 20px 60px 20px;
    background-color: #000000;
    background-image: url("https://www.transparenttextures.com/patterns/white-wall-3-2.png");
  }
  
  .project-item {
    margin-bottom: 30px;
    padding: 20px 20px;
  }
  
  .project-item img {
    width: 110%;
    height: auto;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  .projects h2 {
    padding: 40px 0 40px 0 ;
    font-family: 'Montserrat', sans-serif;
  }
  .project-item h3 {
    font-size: 1.5rem;
    margin-top: 20px;
    padding-bottom:20px;
    font-family: 'Montserrat', sans-serif;
  }
  
  .project-item p {
    font-size: 1.1rem;
    line-height: 1.6;
    font-family: 'Source Sans Pro', sans-serif;
  }

  .project-item .btn-primary {
    margin-top: auto;
    font-family: 'Montserrat', sans-serif;
    background-color:#4C566A;
    border: 0;
  }

  .project-item .btn-primary:hover{
    background-color:#D8DEE9;
    color: #3B4252;
  }
  @media (max-width:1200px) {
    .project-item {
      margin-bottom: 50px;
      padding: 40px 60px;
    }

    .project-item img {
      width: 80%;
    }
  }


  .heading h2{
    position: relative;
    font-size: 3.2rem;
    text-align: center;
    padding:90px;
    background-color: #000000;
    background-image: url("https://www.transparenttextures.com/patterns/skulls.png");
    margin: 0;
  }

  .artgallery-container {
    position: relative;
    padding: 20px;
    column-count: 3;
    column-gap: 10px;
    background-color: #000000;
    background-image: url("https://www.transparenttextures.com/patterns/skulls.png");

    
  }
  
  .artgallery-item {
    width: calc(100% - 10px);
    margin-bottom: 10px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
  }
  
  @media screen and (max-width: 767px) {
    .artgallery-container {
      column-count: 2;
    }
  }
  
  .artgallery-item img {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease-in-out;
    display: block;
  }
  
  .artgallery-item:hover {
    transform: scale(1.0001);
  }
  
  .artgallery-item:hover img {
    transform: scale(1.02);
  }


  .heading2 h2{
    position: relative;
    font-size: 3.2rem;
    text-align: center;
    padding:90px;
    background-color: #000000;
    background-image: url("https://www.transparenttextures.com/patterns/shley-tree-1.png");
    margin: 0;
  }

  .photogallery-container {
    position: relative;
    padding: 20px;
    column-count: 3;
    column-gap: 10px;
    background-color: #000000;
    background-image: url("https://www.transparenttextures.com/patterns/shley-tree-1.png");
  }
  
  .photogallery-item {
    width: calc(100% - 10px);
    margin-bottom: 10px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
  }
  
  @media screen and (max-width: 767px) {
    .photogallery-container {
      column-count: 2;
    }
  }
  
  .photogallery-item img {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease-in-out;
    display: block;
  }
  
  .photogallery-item:hover {
    transform: scale(1.0001);
  }
  
  .photogallery-item:hover img {
    transform: scale(1.02);
  }
  
  .my-music-section {
    backdrop-filter: blur(200px);
    text-align: center;
    padding:60px;
    
  }
  .my-music-section h2 {
    font-size: 3.2rem;
    padding:30px;
  }

  .currplaying iframe{
    padding:50px;
    width: 100%;
    height: auto;
  }

  